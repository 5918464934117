import React from 'react';
import { ErrorDisplay } from '../../../Util/Interfaces';
import NameInput from '../Fields/NameInput/NameInput';
// import styles from './SetNameForm.module.scss';

export interface SetNameFormPropsType {
    onSubmit: (e: any) => void;
    error?: ErrorDisplay
    first_name: string
    last_name: string
    setFirstName: (value: string) => void
    setLastName: (value: string) => void
}

function SetNameForm({
    onSubmit,
    error,
    first_name,
    last_name,
    setFirstName,
    setLastName
}: SetNameFormPropsType) {
    return (
        <form onSubmit={onSubmit}>

            <NameInput
                onSubmit={onSubmit}
                value={first_name}
                setValue={setFirstName}
                label="First name"
                inputName="first_name"
                placeholder="Add your first name"
                error={error}
            />

            <NameInput
                onSubmit={onSubmit}
                value={last_name}
                setValue={setLastName}
                label="Last name"
                inputName="last_name"
                placeholder="Add your last name"
                error={error}
            />

        </form>
    )
}

export default SetNameForm;
